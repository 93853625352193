import { getAddressInfo } from './getAddressInfo';
import { getDirectionParamsFromAddressInfo } from './getDirectionParamsFromAddressInfo';
import { calcPriceAsync } from '~~/api/contentInfo/calc';

/**
 * Asynchronously calculates the transportation rate between two addresses.
 * This function first geocodes 'from' and 'to' addresses, then uses the obtained information
 * to calculate the transportation rate using the 'calcPriceAsync' function.
 * @param {string} to - The destination address for the transportation calculation.
 * @param {string} from - The origin address for the transportation calculation.
 * @returns {Promise<number|null>} A promise that resolves to the calculated transportation rate or null if the calculation cannot be completed.
 */
export const getNeedCalculate = async (to, from) => {
    const currency = {
        guid: 'd177c117-631a-4eff-960d-56b6ccd66401',
        code: 398,
    };
    let result = null;
    await Promise.all([getAddressInfo(from), getAddressInfo(to)]).then(results => {
        return calcPriceAsync({
            direction: {
                from: getDirectionParamsFromAddressInfo(results[0]),
                to: getDirectionParamsFromAddressInfo(results[1]),
            },
            currency: {
                guid: currency.guid,
                code: currency.code,
            },
        }).then(res => {
            result = res.data.calculation.transportationRate;
        });
    });

    return result;
};
