import httpCommon from '@/api/httpCommon/httpCommon';

/**
 * Asynchronously calculates price by sending a POST request.
 * Uses the configured Axios instance from httpCommon to send the request.
 * @param {object} data - The payload to be sent with the POST request.
 *                        Contains the data needed for price calculation.
 * @returns {Promise<AxiosResponse>} A promise that resolves to the Axios response object.
 */
const calcPriceAsync = async data => {
    return await httpCommon().post('get-price', data, {
        maxBodyLength: Infinity,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export { calcPriceAsync };
